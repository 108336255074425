import { createStore } from 'easy-peasy'
import { model } from './models'
import appConfig from '../app-config-global'
import { getEnv } from '../TemplateConstants/common'

export * from './hooks'
export const store = createStore(
  model,
  {
    name: `${appConfig.name}-${getEnv()}`,
    /* Update this version number when you would like to force a new storage key on the client browser.
    * When store changes structure or jou just want to wipe thier Local or Session storage for this app on thier next visit.
    * src/TemplateConstants/store/model/LocalPresist.ts OR src/TemplateConstants/store/model/SessionPresist.ts
    */
    version: 0.0
  }
)