import { BaseController as BaseApiController } from 'TemplateConstants/controller'
import authProvider from 'Services/AuthProviderLocal'
import apiConfig from "../api-config"

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  readonly apiPathTravel: string
  readonly apiPathSearch: string
  readonly apiPathVaccine: string
  readonly apiPathPerson: string

  constructor () {
    super()

    this.apiPathTravel = apiConfig[this.configKey].paths.apiPathTravel
    this.apiPathSearch = apiConfig[this.configKey].paths.apiPathSearch
    this.apiPathVaccine = apiConfig[this.configKey].paths.apiPathVaccine
    this.apiPathPerson = apiConfig[this.configKey].paths.apiPathPersonalInformation

    
  } 

  /*
    If you do not use cognito, implement your authorization header logic here
  */
  async getAuthorizationHeaders (): Promise<Record<string, string>> {
    const token = await authProvider.getToken()

    return {
      Authorization: `Bearer ${token}`
    }
  }
}
