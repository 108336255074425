import { BaseController } from './BaseController'
import { components } from "../../Types/travelApi"

export type TextsResponse = components["schemas"]["Texts"]

class AppController extends BaseController {

  readonly getTextsUrl: string

  constructor () {
    super()
    this.getTextsUrl = `${this.apiHost}/text-v1/texts?appId=travel&language=en`
  }

  async getTexts (): Promise<TextsResponse> {
    const response = await this.useFetch('GET', 'lookups', this.getTextsUrl)
    return await this.checkResponse(response);
  }
}

const appController = new AppController()
export { appController }
