import { Action, action } from 'easy-peasy'

interface State {
  hasAccess: boolean
  isLoading: boolean
  sessionHasExpired: boolean
}
interface Actions {
  setHasAccess: Action<this, boolean>
  setIsLoading: Action<this, boolean>
  setSessionExpired: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {}

export interface ILoaderModel extends State, Actions, Thunks {}

export const LoaderModel: ILoaderModel = {
  hasAccess: false,
  isLoading: true,
  sessionHasExpired: false,

  // Actions
  setHasAccess: action((state, payload) => {
    state.hasAccess = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setSessionExpired: action((state, payload) => {
    state.sessionHasExpired = payload
  })
}
