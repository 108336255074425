import { BaseController } from './BaseController'
import { components } from '../../Types/travelApi'

export type StepsResponse = components['schemas']['Status']
export type TransportResponse = components['schemas']['Transports']
export type SegmentInterface = components['schemas']['Segment']
export type TrainingResponse = components['schemas']['Training']
export type VaccinationResponse = components['schemas']['Vaccination']
export type ProgramDTO = components['schemas']['Program']
export type AirlinesDTO = components['schemas']['Airlines']
export type AirportsDTO = components['schemas']['Airports']
export type RailwaysDTO = components['schemas']['Railways']
export type StationsDTO = components['schemas']['Stations']
export type StatesDTO = components['schemas']['States']
export type HighRiskDTO = components['schemas']['HighRisk']
export type CitiesDTO = components['schemas']['Cities']
export type ProfileDTO = components['schemas']['Profile']
export type LanguagesDTO = components['schemas']['Languages']
export type PersonDetailsDTO = components['schemas']['Person']
export type HighRiskRequirementDTO = components['schemas']['HighRiskRequirement']
export type HighRiskEventsDTO = components['schemas']['HighRiskEvents']

class TravelController extends BaseController {
	readonly getTransportModesUrl: string
	readonly getPassportsUrl: string
	readonly getVisasUrl: string
	readonly getSegmentUrl: string
	readonly getProgramUrl: string
	readonly getTrainingUrl: string
	readonly getVaccinationUrl: string
	readonly getAirlinesUrl: string
	readonly getAirportsUrl: string
	readonly getCitiesUrl: string
	readonly getRailwaysUrl: string
	readonly getStationsUrl: string
	readonly getStatesUrl: string
	readonly getPersonsDetails: string
	readonly getTripHighRiskUrl: string
	readonly getHighRiskUrl: string
	readonly getHighRiskRequirementUrl: string
	readonly getHighRiskEventUrl: string
	readonly getLanguagesUrl: string
	readonly getProfilesUrl: string

	constructor() {
		super()
		this.getTransportModesUrl = `${this.apiHost}/${this.apiPathTravel}/transport-modes`
		this.getProfilesUrl = `${this.apiHost}/${this.apiPathPerson}/travel-profiles`
		this.getVisasUrl = `${this.apiHost}/${this.apiPathTravel}/visas`
		this.getPassportsUrl = `${this.apiHost}/${this.apiPathTravel}/passports`
		this.getSegmentUrl = `${this.apiHost}/${this.apiPathTravel}/segments`
		this.getProgramUrl = `${this.apiHost}/${this.apiPathTravel}/programs`
		this.getTrainingUrl = `${this.apiHost}/${this.apiPathTravel}/training`
		this.getVaccinationUrl = `${this.apiHost}/${this.apiPathTravel}/vaccination`
		this.getAirlinesUrl = `${this.apiHost}/${this.apiPathTravel}/airlines`
		this.getAirportsUrl = `${this.apiHost}/${this.apiPathTravel}/airports`
		this.getCitiesUrl = `${this.apiHost}/${this.apiPathTravel}/cities`
		this.getRailwaysUrl = `${this.apiHost}/${this.apiPathTravel}/railways`
		this.getStationsUrl = `${this.apiHost}/${this.apiPathTravel}/stations`
		this.getStatesUrl = `${this.apiHost}/${this.apiPathTravel}/states`
		this.getPersonsDetails = `${this.apiHost}/${this.apiPathTravel}/persons`
		this.getTripHighRiskUrl = `${this.apiHost}/${this.apiPathTravel}/high-risk/trips`
		this.getHighRiskUrl = `${this.apiHost}/${this.apiPathTravel}/high-risk`
		this.getHighRiskRequirementUrl = `${this.apiHost}/${this.apiPathTravel}/high-risk/requirements`
		this.getHighRiskEventUrl = `${this.apiHost}/${this.apiPathTravel}/high-risk/events`
		this.getLanguagesUrl = `${this.apiHost}/${this.apiPathTravel}/languages`
	}

	//PERSON DETAILS
	async getPersonDetails(krbName: string): Promise<PersonDetailsDTO> {
		const response = await this.useFetch('GET', 'getPersonDetails', `${this.getPersonsDetails}/${krbName}`)
		return await this.checkResponse(response)
	}

	//SEGMENTS
	async getSegments(tripId: string): Promise<any> {
		const response = await this.useFetch('GET', 'getSegments', `${this.getSegmentUrl}?tripId=${tripId}`)
		return await this.checkResponse(response)
	}

	async getSegment(id: string): Promise<TransportResponse> {
		const response = await this.useFetch('GET', 'getSegment', `${this.getSegmentUrl}/${id}`)
		return await this.checkResponse(response)
	}

	//PROGRAMS
	async getProgram(id: string): Promise<ProgramDTO> {
		const response = await this.useFetch('GET', 'getProgram', `${this.getProgramUrl}/${id}`)
		return await this.checkResponse(response)
	}

	//CITIES
	async getCities(q: string, countryCode?: string): Promise<CitiesDTO> {
		const response = await this.useFetch('GET', `getCities`, `${this.getCitiesUrl}?q=${q}${countryCode ? `&countryCode=${countryCode}` : ''}`)
		return await this.checkResponse(response)
	}

	//LANGUAGES
	async getLanguages(): Promise<LanguagesDTO> {
		const response = await this.useFetch('GET', `getLanguages`, `${this.getLanguagesUrl}`)
		return await this.checkResponse(response)
	}

	//AIRLINE
	async getAirlines(q: string): Promise<AirlinesDTO> {
		const response = await this.useFetch('GET', `getAirlines`, `${this.getAirlinesUrl}?q=${q}`)
		return await this.checkResponse(response)
	}

	async getAirports(q: string, countryCode?: string): Promise<AirportsDTO> {
		const response = await this.useFetch('GET', `getAirports`, `${this.getAirportsUrl}?q=${q}${countryCode ? `&countryCode=${countryCode}` : ''}`)
		return await this.checkResponse(response)
	}

	//RAILWAYS
	async getRailways(): Promise<RailwaysDTO> {
		const response = await this.useFetch('GET', 'getRailways', `${this.getRailwaysUrl}`)
		return await this.checkResponse(response)
	}

	//STATIONS
	async getStations(q: string, stationType: 'bus' | 'train', countryCode?: string): Promise<StationsDTO> {
		const response = await this.useFetch('GET', 'getStations', `${this.getStationsUrl}?q=${q}&stationType=${stationType}${countryCode ? `&countryCode=${countryCode}` : ''}`)
		return await this.checkResponse(response)
	}

	//STATE
	async getStates(countryCode: string): Promise<StatesDTO> {
		const response = await this.useFetchWithoutAbort('GET', `${this.getStatesUrl}?countryCode=${countryCode}`)
		return await this.checkResponse(response)
	}

	//HIGH RISK REQUIREMENTS
	async getHighRiskRequirementsByCountry(country: string): Promise<HighRiskRequirementDTO> {
		const response = await this.useFetch('GET', `getHighRiskRequirementsByCountry`, `${this.getHighRiskRequirementUrl}?countryCode=${country}`)
		return await this.checkResponse(response)
	}

	async getHighRiskRequirementsByEvent(tripId: string, eventId: string, segmentId: string, countryCode: string): Promise<HighRiskRequirementDTO> {
		const response = await this.useFetch(
			'GET',
			`getHighRiskRequirementsByCountry`,
			`${this.getHighRiskRequirementUrl}?countryCode=${countryCode}&tripId=${tripId}&eventId=${eventId}&segmentId=${segmentId}`
		)
		return await this.checkResponse(response)
	}

	async getHighRiskRequirements(requirementId: string): Promise<HighRiskRequirementDTO> {
		const response = await this.useFetch('GET', `getHighRiskRequirements`, `${this.getHighRiskRequirementUrl}/${requirementId}`)
		return await this.checkResponse(response)
	}

	async createHighRiskRequirement(body: HighRiskRequirementDTO): Promise<HighRiskRequirementDTO> {
		const response = await this.useFetch('POST', `createHighRiskRequirement`, `${this.getHighRiskRequirementUrl}`, body)
		return await this.checkResponse(response)
	}

	async updateHighRiskRequirement(body: HighRiskRequirementDTO): Promise<HighRiskRequirementDTO> {
		const response = await this.useFetch('PUT', `updateHighRiskRequirement`, `${this.getHighRiskRequirementUrl}`, body)
		return await this.checkResponse(response)
	}

	//HIGH RISK EVENT
	async getHighRiskEventsByCountry(country: string): Promise<HighRiskEventsDTO> {
		const response = await this.useFetch('GET', `getHighRiskEventsByCountry`, `${this.getHighRiskEventUrl}?countryCode=${country}`)
		return await this.checkResponse(response)
	}

	//HIGH RISK
	async getTripHighRisk(id: string): Promise<HighRiskDTO> {
		const response = await this.useFetch('GET', `getTripHighRisks`, `${this.getTripHighRiskUrl}/${id}`)
		return await this.checkResponse(response)
	}

	async createHighRisk(body: HighRiskDTO): Promise<HighRiskDTO> {
		const response = await this.useFetch('POST', `postHighRisks`, `${this.getHighRiskUrl}`, body)
		return await this.checkResponse(response)
	}

	async updateHighRisk(body: HighRiskDTO): Promise<any> {
		const response = await this.useFetch('PUT', `putHighRisks`, `${this.getHighRiskUrl}`, body)
		return await this.checkResponse(response, true)
	}

	async updateHighRiskStatus(id: string): Promise<any> {
		const response = await this.useFetch('PUT', `putHighRisksStatus`, `${this.getHighRiskUrl}/${id}/pending`)
		return await this.checkResponse(response, true)
	}

	//SEGMENTS
	async deleteSegment(id: string): Promise<TransportResponse> {
		const response = await this.useFetch('DELETE', 'deleteSegment', `${this.getSegmentUrl}/${id}`)
		return await this.checkResponse(response, true)
	}

	async postSegment(body: SegmentInterface | any): Promise<TransportResponse> {
		const response = await this.useFetch('POST', 'postSegment', `${this.getSegmentUrl}`, body)
		return await this.checkResponse(response)
	}

	async putSegment(body: SegmentInterface | any): Promise<TransportResponse> {
		const response = await this.useFetch('PUT', 'putSegment', `${this.getSegmentUrl}`, body)
		return await this.checkResponse(response, true)
	}

	async getTransportModes(): Promise<TransportResponse> {
		const response = await this.useFetch('GET', 'getTransportModes', `${this.getTransportModesUrl}`)
		return await this.checkResponse(response)
	}

	async getTrainingStatus(): Promise<TrainingResponse> {
		const response = await this.useFetch('GET', 'getTrainingStatus', `${this.getTrainingUrl}`)
		return await this.checkResponse(response)
	}

	async getVaccinationStatus(): Promise<VaccinationResponse> {
		const response = await this.useFetch('GET', 'getVaccinationStatus', `${this.getVaccinationUrl}`)
		return await this.checkResponse(response)
	}

	async getPassports(countryCode: any): Promise<any> {
		const response = await this.useFetch('GET', 'getPassports', `${this.getPassportsUrl}?countryCode=${countryCode}`)
		return await this.checkResponse(response)
	}

	async createPassport(body: any): Promise<any> {
		const response = await this.useFetch('POST', 'addPassport', `${this.getPassportsUrl}`, body)

		return await this.checkResponse(response)
	}

	async updatePassport(body: any): Promise<{ url: string }> {
		const response = await this.useFetch('PUT', 'addPassport', `${this.getPassportsUrl}`, body)

		return await this.checkResponse(response)
	}

	async deletePassport(id: string): Promise<any> {
		const response = await this.useFetch('DELETE', 'deletePassport', `${this.getPassportsUrl}/${id}`)

		return await this.checkResponse(response, true)
	}

	async createVisa(body: any): Promise<any> {
		const response = await this.useFetch('POST', 'addVisa', `${this.getVisasUrl}`, body)

		return await this.checkResponse(response)
	}

	async updateVisa(body: any): Promise<{ url: string }> {
		const response = await this.useFetch('PUT', 'addVisa', `${this.getVisasUrl}`, body)

		return await this.checkResponse(response)
	}

	async deleteVisa(id: string): Promise<any> {
		const response = await this.useFetch('DELETE', 'deleteVisa', `${this.getVisasUrl}/${id}`)

		return await this.checkResponse(response, true)
	}

	async uploadPassport(url: string | any, file: any): Promise<any> {
		let response = await this.useFetch('PUT', 'uploadPassport', url, file, {}, true)

		if (response.status >= 200 && response.status < 300) {
			return true
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async getStepsConfig(): Promise</* StepsResponse */ any> {
		return {
			steps: [
				{
					step: 'new',
					order: 1
				},
				{
					step: 'acknowledgement',
					order: 2
				},
				{
					step: 'location',
					order: 3
				},
				{
					step: 'itinerary',
					order: 4
				},
				{
					step: 'riskwarning',
					order: 5
				},
				{
					step: 'rejected',
					order: 6
				},
				{
					step: 'accommodation',
					order: 7
				},
				{
					step: 'contact',
					order: 8
				},
				{
					step: 'highriskform',
					order: 9
				},
			]
		}
	}
}

const travelController = new TravelController()
export { travelController }
