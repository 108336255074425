import appConfig from '../../app-config-global'

type EnvReturn = 'dev' | 'staging' | 'production'

type StageReturn = 'develop' | 'release' | 'master'

export const getEnv = (): EnvReturn => {
  switch (getStage()) {
    case 'develop':
      return 'dev'
    case 'release':
      return 'staging'
    case 'master':
      return 'production'
  }
}

export const getStage = (): StageReturn => {
  return appConfig.stage as StageReturn
}

export const timeout = async (ms: number): Promise<void> => {
  return await new Promise(resolve => setTimeout(resolve, ms))
}
