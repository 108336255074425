import { BaseController } from './BaseController'
import { components } from '../../Types/travelApi'
import { components as searchComponents } from '../../Types/searchApi'

export type PlacePredictionDTO = components['schemas']['PlacePrediction']
export type PlaceEstablishmentDTO = components['schemas']['PlaceEstablishment']

type SearchEstablishmentDto = searchComponents['schemas']['Establishment']
type SearchPlacePredictionDto = searchComponents['schemas']['PlacePrediction']

class SearchController extends BaseController {
	readonly getPlaceEstablishmentUrl: string
  readonly getEstablishmentUrl: string

	constructor() {
    super()
    this.getPlaceEstablishmentUrl = `${this.apiHost}/${this.apiPathSearch}/establishments`
    this.getEstablishmentUrl = `${this.apiHost}/${this.apiPathSearch}/establishments`
	}

  async getPlaceEstablishments(input: string): Promise<PlacePredictionDTO[]> {
    const response = await this.useFetch('GET', 'getPlaceEstablishments', `${this.getPlaceEstablishmentUrl}?input=${input}`)
		return await this.checkResponse(response)
  }

  async getPlaceEstablishment(id: string): Promise<PlaceEstablishmentDTO> {
    const response = await this.useFetch('GET', 'getPlaceEstablishments', `${this.getPlaceEstablishmentUrl}/${id}`)
    return await this.checkResponse(response)
  }

  async getEstablishments(input: string, countryCodes?: string[], types?: string[]): Promise<SearchPlacePredictionDto[]> {
		const response = await this.useFetch(
			'GET',
			'getEstablishment',
			`${this.getEstablishmentUrl}?input=${input}${countryCodes && `&countryCodes=${[countryCodes]}`}${types && `&types=${[types]}`}`
		)
		return await this.checkResponse(response)
	}

  async getEstablishment(id: string): Promise<SearchEstablishmentDto> {
		const response = await this.useFetch('GET', 'getEstablishment', `${this.getEstablishmentUrl}/${id}`)
		return await this.checkResponse(response)
	}
}

const searchController = new SearchController()
export { searchController }
