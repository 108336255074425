
import { useStoreState } from 'Store/hooks'
import ReactDOM from 'react-dom'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import { store } from 'Store'
import { Logout } from 'TemplateConstants/components'
import { Initialization } from '@mit/aws-react'
import { useAppInitializer } from 'Services/initializer'
import reportWebVitals from './reportWebVitals'
import appConfig from './app-config-global'
import 'Scss/main.scss'
import '@fortawesome/fontawesome-pro/css/all.min.css'

const WrappedOKTAApp: FC = () => {

	
	const { initializeApp } = useAppInitializer()
	const { appName, appContact, appDescription } = useStoreState((state) => state.AppConfigModel)

	const AppLazyLoad = React.lazy(
		async () =>
			await import('./TemplateConstants/components/Initialization').then(module => ({
				default: module.Initialization
			}))
	)

	return (
		<>
			<Helmet titleTemplate={`${appName} - %s`} defaultTitle={appName}>
				<title>Loading...</title>
				<meta name='description' content={appDescription} />
			</Helmet>
			<React.StrictMode>
				<Router>
					<Switch>
						<Route exact path='/logout' component={Logout} />
						<Route path='/'>
							<Initialization
								App={AppLazyLoad}
								appInitializer={initializeApp}
								appConfigData={appConfig}
								loaderProps={{
									contactEmail: appContact,
									name: appName,
									isLoading: true,
									type: 'Default'
								}}
							/>
						</Route>
					</Switch>
				</Router>
			</React.StrictMode>
		</>
	)
}

ReactDOM.render(
	<StoreProvider store={store}>
		<WrappedOKTAApp />
	</StoreProvider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
