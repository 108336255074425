import { BaseController } from './BaseController'
import { components, paths } from '../../Types/travelApi'

//getTravelrecords
export type TripsResponse = components['schemas']['Trips']
export type TripsQuery = paths['/trips']['get']['parameters']['query']
//getTravelrecord
export type TripResponse = components['schemas']['Trip']
export type TripQuery = paths['/trips/{id}']['get']['parameters']['path']
//addTravelrecord
export type AddTripResponse = components['schemas']['Trip']
export type AddTripQuery = components['schemas']['Trip']
//updateTravelrecord
export type UpdateTripResponse = components['schemas']['Trips']
export type UpdateTripQuery = components['schemas']['Trip']
//cancelTravelrecord
export type CancelTripResponse = paths['/trips/{id}']['delete']['responses'][204]
export type CancelTripQuery = paths['/trips/{id}']['delete']['parameters']['path']
//getTripProgress
export type GetProgressResponse = components['schemas']['Progress']
export type GetProgressQuery = paths['/progress']['get']['parameters']['query']
//updateTripProgress
export type UpdateProgressResponse = components['schemas']['Progress']
export type UpdateProgressQuery = components['schemas']['Progress']
//Contact
export type ContactsResponse = components['schemas']['Contacts']
export type ContactRequest = components['schemas']['ContactRequest']

class TripsController extends BaseController {
	readonly tripsUrl: string
	readonly tripProgressUrl: string
	readonly tripProgramUrl: string
	readonly contactsUrl: string

	constructor() {
		super()
		this.tripsUrl = `${this.apiHost}/${this.apiPathTravel}/trips`
		this.tripProgressUrl = `${this.apiHost}/${this.apiPathTravel}/progress`
		this.tripProgramUrl = `${this.apiHost}/${this.apiPathTravel}/programs`
		this.contactsUrl =  `${this.apiHost}/${this.apiPathTravel}/contacts`
	}
	// TRIPS
	async getTravelrecords(queryParams?: TripsQuery): Promise<TripsResponse> {
		let url = this.addQueryParameters(this.tripsUrl, queryParams)
		const response = await this.useFetch('GET', 'getTravelrecords', url)
		return await this.checkResponse(response)
	}

	async getProgramDetails(programId: string): Promise<any> {
		const response = await this.useFetch('GET', 'getTravelrecords', `${this.tripProgramUrl}/${programId}`)
		return await this.checkResponse(response)
	}

	async getTravelrecord(cfg: TripQuery): Promise<TripResponse> {
		const response = await this.useFetchWithoutAbort('GET', `${this.tripsUrl}/${cfg.id}`)
		return await this.checkResponse(response)
	}

	async addTravelrecord(body: AddTripQuery): Promise<AddTripResponse> {
		const response = await this.useFetch('POST', 'addTravelrecord', `${this.tripsUrl}`, body)
		return await this.checkResponse(response)
	}

	async updateTravelrecord(body: UpdateTripQuery): Promise<UpdateTripResponse> {
		const response = await this.useFetch('PUT', 'updateTravelrecord', `${this.tripsUrl}`, body)
		return await this.checkResponse(response, true)
	}

	async cancelTravelrecord(cfg: CancelTripQuery): Promise<CancelTripResponse | object> {
		const response = await this.useFetch('DELETE', 'cancelTravelrecord', `${this.tripsUrl}/${cfg.id}`)
		return await this.checkResponse(response, true)
	}

	// PROGRESS
	async getTripProgress(queryParams: GetProgressQuery): Promise<GetProgressResponse> {
		let url = this.addQueryParameters(this.tripProgressUrl, queryParams)
		const response = await this.useFetch('GET', 'getTripProgress', url)
		return await this.checkResponse(response)
	}

	async updateTripProgress(data: UpdateProgressQuery): Promise<UpdateProgressResponse | object> {
		const response = await this.useFetch('PUT', 'updateTripProgress', `${this.tripProgressUrl}`, data)
		return await this.checkResponse(response, true)
	}

	// CONTACTS
	async getContacts(contactType: "local" | "emergency" | "self", tripId?: string): Promise<ContactsResponse> {
		const response = await this.useFetch('GET', `getTripContacts-${contactType}`, `${this.contactsUrl}?contactType=${contactType}${tripId ? `&tripId=${tripId}` : ''}`)
		return await this.checkResponse(response)
	}

	async createContacts(body: ContactRequest): Promise<any> {
		const response = await this.useFetch('POST', `postTripContacts-${body.contactType}`, `${this.contactsUrl}`, body)
		return await this.checkResponse(response)
	}

	async updateContacts(body: ContactRequest): Promise<any> {
		const response = await this.useFetch('PUT', `putTripContacts-${body.contactType}`, `${this.contactsUrl}`, body)
		return await this.checkResponse(response, true)
	}
}
const tripsController = new TripsController()
export { tripsController }
