import { BaseController } from './BaseController'
import { components } from '../../Types/travelApi'

export type LocationResponse = components['schemas']['Location']
export type LocationsResponse = components['schemas']['Locations']
export type CountriesResponse = components['schemas']['Countries']
export type CountryResponse = components['schemas']['Country']

function uuid(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

class LocationsController extends BaseController {
	readonly getLocationsUrl: string
	readonly getCountryUrl: string

	constructor() {
		super()
		this.getLocationsUrl = `${this.apiHost}/${this.apiPathTravel}/locations`
		this.getCountryUrl = `${this.apiHost}/${this.apiPathTravel}/countries`
	}

	async getLocation(id: string): Promise<LocationResponse> {
		const response = await this.useFetch('GET', 'getTravelrecord', `${this.getLocationsUrl}/${id}`)
		return await this.checkResponse(response)
	}

    async getLocations(tripId: string): Promise<LocationsResponse> {
		const response = await this.useFetch('GET', 'getTravelrecord', `${this.getLocationsUrl}?tripId=${tripId}`)
		return await this.checkResponse(response)
	}

	async getCountry(countryCode: string): Promise<CountriesResponse> {
		const response = await this.useFetch('GET', `getCountries-${uuid()}`, `${this.getCountryUrl}/${countryCode}`)
		return await this.checkResponse(response)
  }

  async getCountries(): Promise<CountriesResponse> {
    const response = await this.useFetch('GET', `getCountries`, `${this.getCountryUrl}`)
    return await this.checkResponse(response)
  }
}

const locationsController = new LocationsController()
export { locationsController }
