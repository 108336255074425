import { store } from 'Store'
import apiConfig from "../../Api/api-config";

export abstract class BaseController {
	readonly apiHost: string
	readonly apiPathDigitalId: string
	private readonly fetchController: Record<string, any>
	readonly configKey: keyof typeof apiConfig

	constructor() {

		let isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false;
		this.configKey = (isGlobalEnabled ? 'global': 'regional') as keyof typeof apiConfig;
	
		this.apiHost = apiConfig[this.configKey].apiHost ?? ''
		this.apiPathDigitalId = apiConfig[this.configKey].paths.apiPathDigitalId
		this.fetchController = {}
	}

	private async getHeaders(overrideHeaders: Record<string, string> = {}, file: boolean = false, impersonateKerb: string = ''): Promise<Headers> {
		const authorizationHeader = await this.getAuthorizationHeaders()

		let headers: { [key: string]: string } = {}

		if (!file) {
			headers['Content-Type'] = 'application/json'
			headers = { ...headers, ...authorizationHeader }
		} else {
			headers['Content-Type'] = 'image/jpeg'
			headers = { ...headers }
		}

		if (impersonateKerb !== '') {
			headers['X-Impersonated-User'] = impersonateKerb
		}
		console.log('useFetch HEADERS::: ', { ...headers, ...overrideHeaders })
		return new Headers({ ...headers, ...overrideHeaders })
	}

	public addQueryParameters(url: string, params: any): string {
		let query = Object.keys(params).map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
		return query.length === 0 ? url : `${url}?${query.join('&')}`
	}

	public async checkResponse(response: any, noPayloadExpected?: boolean): Promise<any | never> {
		const successCodes = [200, 201, 204]
		if (successCodes.includes(response.status)) {
			if (noPayloadExpected) return true
			else {
				const data = await response.json()
				if (data) {
					return data
				} else {
					throw true
				}
			}
		} else {
			const text = await response.text()
			if (text) {
				return await Promise.reject(text)
			} else {
				throw false
			}
		}
	}

	async useFetch(
		method: string,
		fetchKey: string,
		url: string,
		body: any = null,
		overrideHeaders: Record<string, string> = {},
		file: boolean = false,
		impersonateKerb: string = ''
	): Promise<any> {
		if (this.fetchController[fetchKey] !== null && this.fetchController[fetchKey] !== undefined) {
			this.fetchController[fetchKey].abort()
		}

		this.fetchController[fetchKey] = new AbortController()
		const { signal } = this.fetchController[fetchKey]

		const options: any = {
			method: method,
			headers: await this.getHeaders(overrideHeaders, file, impersonateKerb),
			signal
		}

		if (body !== null) {
			options.body = file ? body : JSON.stringify(body)
		}

		const response = await fetch(url, options)

		this.fetchController[fetchKey] = null

		return response
	}

	async useFetchWithoutAbort(
		method: string,
		url: string,
		body: any = null,
		overrideHeaders: Record<string, string> = {},
		file: boolean = false,
		impersonateKerb: string = ''
	): Promise<any> {
		const options: any = {
			method: method,
			headers: await this.getHeaders(overrideHeaders, file, impersonateKerb)
		}

		if (body !== null) {
			options.body = file ? body : JSON.stringify(body)
		}

		const response = await fetch(url, options)

		return response
	}

	abstract getAuthorizationHeaders(): Promise<Record<string, string>>
}
