import { persist } from 'easy-peasy'

// TEMPLATE STORES - NEVER EDIT THESE, IF A TEMPLATE UPDATE IS DONE THEN HSE WILL BE OVERRIDDEN
import { ITemplateModel, TemplateModel } from 'TemplateConstants/store/model'

// APP SPECIFIC STORES - EDIT THESE OR ADD MORE HERE:
import { IAppConfigModel, AppConfigModel } from './AppConfig'
import { IGlobalModel, GlobalModel } from './Global'
import { ILocalStorageModel, LocalStorageModel } from './LocalPresist'
import { ISessionStorageModel, SessionStorageModel } from './SessionPresist'
import { ITravelModel, TravelModel } from './Travel'

export interface Model extends ITemplateModel {
  AppConfigModel: IAppConfigModel
  GlobalModel: IGlobalModel
  LocalStorageModel: ILocalStorageModel
  SessionStorageModel: ISessionStorageModel
  TravelModel: ITravelModel
}

export const model: Model = {
  // FOR DO NOT TOUCH EVER - TemplateModel
  ...TemplateModel,
  // FOR APP CONFIG MANEGEMENT - AppConfigModel
  AppConfigModel,
  // FOR GLOBAL STATE MANAGEMENT - GlobalModel
  GlobalModel,
  TravelModel,
  // FOR LOCALSTORAGE MANAGEMENT - LocalStorageModel
  LocalStorageModel: persist(LocalStorageModel, {
    storage: 'localStorage'
  }),
  // FOR SESSIONSTORAGE MANAGEMENT - SESSIONSTORAGE
  SessionStorageModel: persist(SessionStorageModel, {
    storage: 'sessionStorage'
  })
}