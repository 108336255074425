import React, { FC, useEffect } from 'react'
import { Profile, DropdownItemProps, DropDownState } from '@mit/hui'

import { ProfileController, travelController } from 'Api/controller'
import { useStoreActions, useStoreState } from 'Store'
import AuthProviderLocal from 'Services/AuthProviderLocal'

const controller = new ProfileController()

export const LoggedInUser: FC = () => {
	const { profile, profilePicture } = useStoreState((store) => store.ProfileModel)
	const { updateProfile, updateProfilePicture, updatePersonType } = useStoreActions((actions) => actions.ProfileModel)

	const subMenuData: DropdownItemProps[] = [
		{
			icon: 'sign-out',
			text: 'Logout',
			state: DropDownState.None,
			onClick: async () => {
				await AuthProviderLocal.logout()
			}
		}
	]

	useEffect(() => {
		if (profilePicture === '') {
			controller
				.getProfilePicture()
				.then((data: any) => {
					const url = URL.createObjectURL(data)
					updateProfilePicture(url)
				})
				.catch((error) => console.error(error))
		}
		// eslint-disable-next-line
	}, [])

	return <Profile name={profile?.display_name} subtitle={profile?.department} imageUrl={profilePicture} submenu={subMenuData} />
}
