import { Action, action } from 'easy-peasy'
import { components } from 'Types/travelApi';
import { components as PIComponents } from 'Types/personApi';

type TransportDTO = components["schemas"]["Transport"];
type SegmentDTO = components["schemas"]["Segment"];
type TripDTO = components["schemas"]["Trip"];
type ProgressDTO = components["schemas"]["Progress"];
type LanguageDTO = PIComponents["schemas"]["Language"];
type TravelProfileDTO = PIComponents['schemas']['TravelProfile']
type HighRiskRequirementDTO = components['schemas']['HighRiskRequirement']
type HighRiskContactDTO = components['schemas']['HighRiskContact']
type EstablishmentDTO = components['schemas']['Establishment']

export type View = 'current' | 'past' | 'rejected'
interface State {
  travelProfile: TravelProfileDTO | null
  travelData: object[] | null
  selectedTravelRecord: TripDTO | null
  selectedTravelSegment: SegmentDTO | null
  tripProgress: ProgressDTO | any
  tripProgram: any
  activeStep: string
  listView: View
  transportModes: TransportDTO[]
  isLoadingGlobal: boolean
  isLoadingItinerary: boolean
  countries: any[];
  languages: LanguageDTO[];
  highRiskData: SegmentDTO | null
  highRiskRequirementData: HighRiskRequirementDTO | null
  selectedContact: HighRiskContactDTO | null
  selectedEstablishment: EstablishmentDTO | null
  passports: any[]
}
interface Actions {
  setTravelProfile:  Action<this, TravelProfileDTO>
  setTravelData: Action<this, any>
  setSelectedTravelRecord: Action<this, TripDTO | null>
  setSelectedTravelSegment: Action<this, SegmentDTO | null>
  setTripProgress: Action<this, ProgressDTO | any>
  setActiveStep: Action<this, any>
  setTripProgram: Action<this, any>
  setListView: Action<this, View>
  setTransportModes: Action<this, TransportDTO[]>
  setIsLoadingGlobal: Action<this, boolean>
  setIsLoadingItinerary: Action<this, boolean>
  setCountries: Action<this, any[]>
  setLanguages: Action<this, LanguageDTO[]>
  setHighRiskData: Action<this, SegmentDTO | null>
  setHighRiskRequirementData: Action<this, HighRiskRequirementDTO | null>
  setSelectedContact: Action<this, HighRiskContactDTO | null>
  setSelectedEstablishment: Action<this, EstablishmentDTO | null>
  setPassports: Action<this, any[]>
}
// eslint-disable-next-line
interface Thunks {
}

export interface ITravelModel extends State, Actions, Thunks {}

export const TravelModel: ITravelModel = {

  travelProfile: null,
  setTravelProfile: action((state, payload) => {
    state.travelProfile = payload
  }),

  travelData: null,
  setTravelData: action((state, payload) => {
    state.travelData = payload
  }),
  
  selectedTravelRecord: null,
  setSelectedTravelRecord: action((state, payload) => {
    state.selectedTravelRecord = payload
  }),

  selectedTravelSegment: null,
  setSelectedTravelSegment: action((state, payload) => {
    state.selectedTravelSegment = payload
  }),

  tripProgress: {
    acknowledgement: false,
    emergencyInfo: false,
    training: false,
    travelerInfo: false,
    tripId: '',
    tripLeg: false,
  },
  setTripProgress: action((state, payload) => {
    state.tripProgress = payload
  }),

  activeStep: "",
  setActiveStep: action((state, payload) => {
    state.activeStep = payload
  }),
  
  listView: "current",
  setListView: action((state, payload) => {
    state.listView = payload
  }),

  transportModes: [],
  setTransportModes: action((state, payload) => {
    state.transportModes = payload
  }),

  isLoadingGlobal: true,
  setIsLoadingGlobal: action((state, payload) => {
    state.isLoadingGlobal = payload
  }),

  isLoadingItinerary: false,
  setIsLoadingItinerary: action((state, payload) => {
    state.isLoadingItinerary = payload
  }),

  tripProgram: null,
  setTripProgram: action((state, payload) => {
    state.tripProgram = payload
  }),

  countries: [],
  setCountries: action((state, payload) => {
    state.countries = payload
  }),

  languages: [],
  setLanguages: action((state, payload) => {
    state.languages = payload
  }),

  highRiskData: null,
  setHighRiskData: action((state, payload) => {
    state.highRiskData = payload
  }),

  highRiskRequirementData: null,
  setHighRiskRequirementData: action((state, payload) => {
    state.highRiskRequirementData = payload
  }),

  selectedEstablishment: null,
  setSelectedEstablishment: action((state, payload) => {
    state.selectedEstablishment = payload
  }),

  selectedContact: null,
  setSelectedContact: action((state, payload) => {
    state.selectedContact = payload
  }),

  passports: [],
  setPassports: action((state, payload) => {
    state.passports = payload
  }),
}
