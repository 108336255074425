import { Action, action } from 'easy-peasy'
import { AtlasTheme } from '@mit/hui'
import { BreakPoints } from '../../Types/GlobalTypes'


interface State {
  appName: string
  appDescription: string
  appContact: string
  appTheme: AtlasTheme
  googleAnalyticsId: string
  appTexts: any | null
  stepsConfig: any,
  breakPoint: BreakPoints | 0
}

// eslint-disable-next-line
interface Actions {
  setAppTexts: Action<this, any>
  setStepsConfig: Action<this, any>
  setBreakPoint: Action<this, any>
}
// eslint-disable-next-line
interface Thunks {
  getText: (texts: any, textId: string, fallbackText: string, prefix?: string) => string,
  searchText: (texts: any, textId: string, fallbackText: string, prefix?: string) => string
}

export interface IAppConfigModel extends State, Actions, Thunks {}

export const AppConfigModel: IAppConfigModel = {
  appName: 'Travel',
  appDescription: 'An MIT Atlas app to help you manage you travel safer during this covid pandemic.',
  appContact: 'travel-admin@mit.edu',
  appTheme: 'travel',
  googleAnalyticsId: 'G-RMYC97YZYV',
  getText: (texts: any, textId: string, fallbackText: string, prefix: string = "") => {

    if (!texts) {
      return fallbackText;
    }

    if (prefix && prefix.length > 0) {
      textId = "travel." + prefix.toLowerCase() + "." + textId;
    }

    var result = texts.filter((itm: any) => itm.textId == textId)[0];

    if (result) {
      return result.text;
    }

    return fallbackText;
  },
  searchText: (texts: any, textId: string, fallbackText: string, prefix: string = "") => {

    if (!texts) {
      return fallbackText;
    }

    if (prefix && prefix.length > 0) {
      textId = "travel." + prefix.toLowerCase() + "." + textId;
    }

    var result = texts.filter((itm: any) => itm.textId.indexOf(textId) > -1)[0];

    if (result) {
      return result.text;
    }

    return fallbackText;
  },
  appTexts: null,
  setAppTexts: action((state, payload) => {
    state.appTexts = payload;
  }),
  stepsConfig: [],
  setStepsConfig: action((state, payload) => {
    state.stepsConfig = payload;
  }),
  breakPoint: 0,
  setBreakPoint: action((state, payload) => {

    let breakPointMap:{ [key: string]: number} = {
      xSmall: BreakPoints.xs,
      small: BreakPoints.sm,
      medium: BreakPoints.md,
      large: BreakPoints.lg,
      xLarge: BreakPoints.xl
    }

    let mapped = breakPointMap[payload] || 0
    state.breakPoint = mapped;
  }),
}
