const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPathTravel": "travel-v1",
      "apiPathSearch": "search-v1",
      "apiPathVaccine": "vaccine-v1",
      "apiPathPersonalInformation": "personal-information-v1",
      "apiPathDigitalId": "digital-id-v1"
    }
  },
  "global": {
    "apiHost": "https://travel.mit.edu/api",
    "paths": {
      "apiPathTravel": "travel/v1",
      "apiPathSearch": "search/v1",
      "apiPathVaccine": "vaccine/v1",
      "apiPathPersonalInformation": "personal-information/v1",
      "apiPathDigitalId": "digital-id/v1"
    }
  }
};
export default apiConfigs;