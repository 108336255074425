import { Action, action } from 'easy-peasy'

interface State {
  auth: object
}
interface Actions {
  setAuth: Action<this, object>
}
// eslint-disable-next-line
interface Thunks {}

export interface IGlobalModel extends State, Actions, Thunks {}

export const GlobalModel: IGlobalModel = {
  auth: {},

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  })
}