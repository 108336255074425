
import { AuthProvider } from '@mit/aws-react'
import appConfig from "../app-config-global";

class AuthProviderLocal {

  getToken = async (): Promise<any> => {
    //console.log('getToken', appConfig.regions[0])
    const authorizationToken = await new AuthProvider(Object.assign(appConfig)).getToken()
    // console.log('getToken', authorizationToken)
    return authorizationToken;
  }

  logout = async (): Promise<any> => {
    await new AuthProvider(Object.assign(appConfig)).signOut()
  }

  // refresToken = async (): Promise<any> => {
  //   return await new AuthProvider(Object.assign(appConfig)).refreshToken()
  // }
}

const authProviderLocal = new AuthProviderLocal()
export default authProviderLocal
