import { Action, action } from 'easy-peasy'

import {ReactourStep} from "reactour"

interface State {
  showPopupAgain: boolean
  showTour: boolean
  tourSteps: ReactourStep[]

}
interface Actions {
  setShowPopupAgain: Action<this, boolean>
  setShowTour: Action<this, boolean>
  setTourSteps: Action<this, ReactourStep[]>
}
// eslint-disable-next-line
interface Thunks {}

export interface ILocalStorageModel extends State, Actions, Thunks {}

export const LocalStorageModel: ILocalStorageModel = {
  showPopupAgain: true,
  setShowPopupAgain: action((state, payload) => {
    state.showPopupAgain = payload
  }),
  
  showTour: true,
  setShowTour: action((state, payload) => {
    state.showTour = payload
  }),
  
  tourSteps: [],
  setTourSteps: action((state, payload) => {
    state.tourSteps = payload
  })
}
