import { BaseController } from './BaseController'
import { components } from '../../Types/personApi'

export type ProfileDTO = components['schemas']['Profile']
export type LanguagesDTO = components['schemas']['Languages']
export type PassportDTO = components['schemas']['Passport']
export type PassportsDTO = components['schemas']['Passports']
export type VisaDTO = components['schemas']['Visa']
export type VisasDTO = components['schemas']['Visas']
export type ContactsDTO = components['schemas']['Contacts']
export type ContactDTO = components['schemas']['Contact']
export type TravelProfileDTO = components['schemas']['TravelProfile']

class PersonalInformationController extends BaseController {
	readonly getProfileUrl: string
	readonly getTravelProfileUrl: string
	readonly getLanguagesUrl: string
	readonly getPassportsUrl: string
	readonly getVisasUrl: string
	readonly contactUrl: string

	constructor() {
		super()
		this.getProfileUrl = `${this.apiHost}/${this.apiPathPerson}/profile`
		this.getTravelProfileUrl = `${this.apiHost}/${this.apiPathPerson}/travel-profiles`
		this.getLanguagesUrl = `${this.apiHost}/${this.apiPathPerson}/languages`
		this.getPassportsUrl = `${this.apiHost}/${this.apiPathPerson}/passports`
		this.getVisasUrl = `${this.apiHost}/${this.apiPathPerson}/visas`
		this.contactUrl = `${this.apiHost}/${this.apiPathPerson}/emergency-contacts`
	}

	//PROFILE
	async getPersonalInformationProfile(): Promise<ProfileDTO> {
		const response = await this.useFetch('GET', `getPersonProfile`, `${this.getProfileUrl}`)
		return await this.checkResponse(response)
	}

	async putPersonalInformationProfile(body: ProfileDTO): Promise<any> {
		const response = await this.useFetch('PUT', `putPersonProfile`, `${this.getProfileUrl}`, body)
		return await this.checkResponse(response)
	}

	//TRAVEL PROFILE
	async getTravelProfile(): Promise<TravelProfileDTO> {
		const response = await this.useFetchWithoutAbort('GET', `${this.getTravelProfileUrl}`)
		return await this.checkResponse(response)
	}

	async postTravelProfile(body: TravelProfileDTO): Promise<any> {
		const response = await this.useFetch('POST', `postTravelProfile`, `${this.getTravelProfileUrl}`, body)
		return await this.checkResponse(response)
	}

	async putTravelProfile(body: TravelProfileDTO): Promise<any> {
		const response = await this.useFetch('PUT', `putTravelProfile`, `${this.getTravelProfileUrl}`, body)
		return await this.checkResponse(response, true)
	}

	//LANGUAGES
	async getLanguages(): Promise<LanguagesDTO> {
		const response = await this.useFetch('GET', `getLanguages`, `${this.getLanguagesUrl}`)
		return await this.checkResponse(response)
	}

	//PASSPORT
	async getPassports(): Promise<PassportsDTO> {
		const response = await this.useFetch('GET', `getPassports`, `${this.getPassportsUrl}`)
		return await this.checkResponse(response)
	}

	async postPassports(body: PassportDTO): Promise<any> {
		const response = await this.useFetch('POST', `postPassports`, `${this.getPassportsUrl}`, body)
		return await this.checkResponse(response)
	}

	async putPassports(body: PassportDTO): Promise<any> {
		const response = await this.useFetch('PUT', `putPassports`, `${this.getPassportsUrl}`, body)
		return await this.checkResponse(response)
	}

	async deletePassports(id: string): Promise<any> {
		const response = await this.useFetch('DELETE', `deletePassports`, `${this.getPassportsUrl}/${id}`)
		return await this.checkResponse(response, true)
	}

	//VISAS
	async getVisas(): Promise<VisasDTO> {
		const response = await this.useFetch('GET', `getVisas`, `${this.getVisasUrl}`)
		return await this.checkResponse(response)
	}

	async postVisas(body: VisaDTO): Promise<any> {
		const response = await this.useFetch('POST', `postVisas`, `${this.getVisasUrl}`, body)
		return await this.checkResponse(response)
	}

	async putVisas(body: VisaDTO): Promise<any> {
		const response = await this.useFetch('PUT', `putVisas`, `${this.getVisasUrl}`, body)
		return await this.checkResponse(response)
	}

	async deleteVisas(id: string): Promise<any> {
		const response = await this.useFetch('DELETE', `deleteVisas`, `${this.getVisasUrl}/${id}`)
		return await this.checkResponse(response, true)
	}

	//Emergency Contacts
	async getEmergencyContacts(): Promise<ContactsDTO> {
		const response = await this.useFetch('GET', `getEmergencyContacts`, `${this.contactUrl}`)
		return await this.checkResponse(response)
	}

	async postEmergencyContacts(body: ContactDTO): Promise<any> {
		const response = await this.useFetch('POST', `postEmergencyContacts`, `${this.contactUrl}`, body)
		return await this.checkResponse(response, true)
	}

	async putEmergencyContacts(body: ContactDTO): Promise<any> {
		const response = await this.useFetch('PUT', `putEmergencyContacts`, `${this.contactUrl}`, body)
		return await this.checkResponse(response, true)
	}

	
	async deleteEmergencyContacts(id: string): Promise<any> {
		const response = await this.useFetch('DELETE', `deleteEmergencyContacts`, `${this.contactUrl}/${id}`)
		return await this.checkResponse(response, true)
	}
}

const personalInformationController = new PersonalInformationController()
export { personalInformationController }
