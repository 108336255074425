import { appInitialized } from '@mit/aws-react'
import { ProfileController, appController, travelController, tripsController } from 'Api/controller'
import { locationsController } from 'Api/controller/LocationsController'
import { personalInformationController } from 'Api/controller/PersonalInformationController'
import { useStoreActions, useStoreState } from 'Store/hooks'
import { useState, useEffect } from 'react'

interface UseAppInitializationReturn {
	initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
	const [initializeApi, setInitializeApi] = useState(false)
	const { setHasAccess, setIsLoading } = useStoreActions((action) => action.LoaderModel)
	const { isLoading, hasAccess } = useStoreState((store) => store.LoaderModel)
	const { appTexts } = useStoreState((store) => store.AppConfigModel)
	const { setAppTexts, setStepsConfig } = useStoreActions((actions) => actions.AppConfigModel)
	const { updateProfile, updateIsStudent, updatePersonType } = useStoreActions((actions) => actions.ProfileModel)
	const { setTransportModes, setCountries, setPassports, setLanguages } = useStoreActions((actions) => actions.TravelModel)

	useEffect(() => {
		appInitialized({
			isLoading: isLoading,
			hasAccess: hasAccess,
			hasError: false
		});
	}, []);

	const initializeApp = async (): Promise<void> => {
		const controller = new ProfileController()

		if (!appTexts) {
			appController
				.getTexts()
				.then((res) => res)
				.then((res) => setAppTexts(res))
				.catch(() => {
					//TODO:: HANDLE ERROR.....
				})
		}

		let mainProfileResponse = await controller.getProfile()

		if (mainProfileResponse?.krb_name) {
			updateProfile(mainProfileResponse)
			await travelController
				.getPersonDetails(mainProfileResponse.krb_name)
				.then((res) => {
					updatePersonType(res.personType)
					updateIsStudent(res.personType === 'STUDENT' || res.personType === 'CURRENT STUDENT')
				})
				.catch(() => { })
		} else {
			setHasAccess(false)
			setIsLoading(false)
			appInitialized({
				isLoading: false,
				hasAccess: false,
				hasError: true
			});
			return
		}

		let countryPromise = locationsController.getCountries()
		let profilePromise = personalInformationController.getTravelProfile()
		let languagesPromise = personalInformationController.getLanguages()

		tripsController.getContacts('emergency')

		//We need to add these calls to the Promise, wasiting time to await them :P
		await Promise.all([
			countryPromise
				.then((countriesResponse) => {
					if (countriesResponse.countries) {
						setCountries(countriesResponse.countries)
					}
				})
				.catch(() => {
					//NA
				}),
			profilePromise
				.then((profileResponse) => {
					if (profileResponse.passports) {
						setPassports(profileResponse.passports ?? [])
					}
				})
				.catch(() => {
					//NA
				}),
			languagesPromise
				.then((languagesResponse) => {
					if (languagesResponse.languages) {
						setLanguages(languagesResponse.languages ?? [])
					}
				})
				.catch(() => {
					//NA
				})
		]).catch(() => {
			//NA
		})

		let configs = await travelController.getStepsConfig().then((res) => res)
		setStepsConfig(configs.steps)
		//TODO:: HANDLE ERROR.....

		let transportModes = await travelController.getTransportModes()
		if (transportModes.transports) setTransportModes(transportModes.transports)

		// if (profile.display_name === '') {
		// 	controller
		// 		.getProfile()
		// 		.then((res) => {
		// 			updateProfile(res)
		// 			if (res.krb_name)
		// 				travelController.getPersonDetails(res.krb_name).then((res) => {
		// 					updatePersonType(res.personType)
		// 					updateIsStudent(res.personType === 'STUDENT' || res.personType === 'CURRENT STUDENT')
		// 				})
		// 		})
		// 		.catch(() => {
		// 			//NA
		// 		})
		// }

		setHasAccess(true)
		setIsLoading(false)
		
		appInitialized({
			isLoading: false,
			hasAccess: true,
			hasError: false
		});
		setInitializeApi(true)

	}

	return { initializeApp }
}
